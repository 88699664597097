<template>
  <div>

    <validation-observer
      ref="infoRules"
      tag="form"
    >

      <b-row class="justify-content-center">
        <b-col

          cols="12"
          md="6"
        >
          <b-card>

            <ul style="list-style-type:none;">
              <li>
                <span class="offerTitle">Offer Id: </span><span class="offerTxt">{{ transactionData.offer.offer_id }}</span>
              </li>

              <li>
                <span class="offerTitle"> Offer Title:</span> <span class="offerTxt">{{ transactionData.offer.offer_title }}</span>
              </li>

            </ul>

            <b-col
              v-for="(transaction , index) in transactionData.type_prices"
              :key="index"
              cols="12"
            >

              <ul>

                <li>
                  <span class="offerSubTitle"> Type Price Name:</span> {{ transaction.type_price_name }}
                </li>

                <li>
                  <div class="mb-1">
                    <span class="offerSubTitle">  Coupons:</span>
                  </div>
                  <ul
                    v-for="(singlecoupon , index) in transaction.coupons"
                    :key="index"
                  >
                    <li>
                      <span class="offerSubTitle"> Coupon: </span> {{ replaceNo(singlecoupon.coupon) }}

                    </li>
                    <li>
                      <span class="offerSubTitle"> Expire At:</span> {{ singlecoupon.expire_date }}

                    </li>
                    <hr>
                  </ul>
                </li>
              </ul>
            </b-col>
          </b-card>
        </b-col>

      </b-row>
    </validation-observer>

  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'

export default {
  data() {
    return {
      loader: false,
      required,
      transactionAmount: '',
      transactionData: [],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const transactionForm = ref({
      mobile: '',

    })
    return {
      getValidationState,
      transactionForm,
    }
  },
  mounted() {
    this.gettransactionDetails()
  },
  methods: {
    replaceNo(word) {
      const masked = word.substring(0, word.length - 4).replace(/[a-z\d]/gi, '*')
    + word.substring(word.length - 4, word.length)
      return masked
    },

    gettransactionDetails() {
      this.loader = true
      axios.get(`waffarha_transaction_details/${this.$route.params.id}`).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.loader = false
          this.transactionAmount = res.data.data.amount
          // eslint-disable-next-line prefer-destructuring
          this.transactionData = res.data.data.details[0]
        }
      })
    },

  },

}
</script>

  <style lang="scss">
.offerSubTitle{
  font-size : 1rem;
  font-weight: 600;
}

.offerTitle{
  font-weight: 600;
}
.offerTxt{
  font-size : 1.2rem;
}
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
